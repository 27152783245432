<script lang="ts" setup>
import IconCalcite from '@/core/components/IconCalcite.vue'
import { useAppStore } from '@/stores'
import type { Component } from 'vue'

const app = useAppStore()

function close() {
  app.closeModal()
}

// Funktion, um einen "key" basierend auf der Komponente zu generieren
function getModalKey(component: Component | null): string | number {
  return component ? component.name || 'modal' : 'modal-null'
}
</script>
<template>

  <Teleport to="body">
    <div v-if="app.state.modal.isOpen" :key="getModalKey(app.state.modal.content)" class="modal" @click.self="close" tabindex="0">
      <div class="modal__content">
        <component 
          :is="app.state.modal.content"
          v-bind="app.state.modal.props" 
        />
      </div>
      <div class="modal__close" @click.stop="close" clickable tabindex="0">
        <icon-calcite iconName="x24" />
      </div>
    </div>
  </Teleport>
  
</template>
<style lang="scss" scoped>
@use "@/sass/mixins";

.modal {
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  backdrop-filter: blur(5px);

  z-index: var(--wh-z-index-modal);

  &__content {
    width: 99.6%;
    height: 99.6%;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: var(--wh-color-bg);
    overflow: hidden;
    padding: 2rem;

    position: relative;
  
    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      left: -50%;
      top: -50%;
      width: 200%;
      height: 200%;
      /* background-color: #399953; */
      background-repeat: no-repeat;
      background-size: 50% 50%, 50% 50%;
      background-position: 0 0, 100% 0, 100% 100%, 0 100%;
      background-image: linear-gradient(0deg, transparent 0%, var(--wh-color-brand) 40%, transparent 70%);
      animation: rotate-2ca291c6 25s linear infinite;
      opacity: .3;
    }
  }

  &__close {
    width: 32px;
    height: 32px;

    position: absolute;
    top: 3rem;
    right: 3rem;

    display: grid;
    place-content: center;

    background-color: var(--wh-color-bg);

    cursor: pointer;

    &:hover {
      filter: brightness(1.6);
    }
  }
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

</style>
