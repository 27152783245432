import type { RouteRecordRaw } from 'vue-router'

export const routes: Array<(RouteRecordRaw)> = [
  {
    name: 'Home',
    path: '/',
    component: () => import('@/pages/home/PageHome.vue'),
    meta: { 
      transition: 'fade',
      priority: 1.0, 
      changefreq: "daily" 
    },
  },
  {
    name: 'Impressum',
    path: '/impressum',
    component: () => import('@/pages/impressum/PageImpressum.vue'),
    meta: { 
      transition: 'slide-up',
      priority: 0.5, 
      changefreq: "yearly" 
    },
  },
  {
    name: 'AGB',
    path: '/agb',
    component: () => import('@/pages/agb/PageAGB.vue'),
    meta: { 
      transition: 'slide-up' ,
      priority: 0.5, 
      changefreq: "yearly" 
    },
  },
  {
    name: 'Datenschutzerklärung',
    path: '/privacy-policy',
    component: () => import('@/pages/privacy-policy/PagePrivacyPolicy.vue'),
    meta: { 
      transition: 'slide-up' ,
      priority: 0.5, 
      changefreq: "yearly" 
    },
  },

  {
    path: '/product/bsb-ef500',
    component: () => import('@/pages/product_bsb-ef500/PageProductEF500.vue'),
    props: true,
    meta: { 
      transition: 'slide-up' ,
      priority: 0.5, 
      changefreq: "weekly" 
    },
  },
  {
    path: '/product/bsb-e450',
    component: () => import('@/pages/product_bsb-e450/PageProductE450.vue'),
    props: true,
    meta: { 
      transition: 'slide-up' ,
      priority: 0.5, 
      changefreq: "weekly" 
    },
  },
  {
    path: '/product/blb-1500',
    component: () => import('@/pages/product_blb-1500/PageProductBLB1500.vue'),
    props: true,
    meta: { 
      transition: 'slide-up' ,
      priority: 0.5, 
      changefreq: "weekly" 
    },
  },
  {
    path: '/product/m60-s-ld',
    component: () => import('@/pages/product_m60/PageProductM60.vue'),
    props: true,
    meta: { 
      transition: 'slide-up' ,
      priority: 0.5, 
      changefreq: "weekly" 
    },
  },
  {
    path: '/product/tb500-2k',
    component: () => import('@/pages/product_tb500/PageProductTB500.vue'),
    props: true,
    meta: { 
      transition: 'slide-up' ,
      priority: 0.5, 
      changefreq: "weekly" 
    },
  },

  //{
  //  path: '/contact/:expert',
  //  name: 'ContactPage',
  //  component: () => import('@/pages/PageContactExpert.vue'),
  //  props: true, // Übergibt `expert` automatisch als Prop an die Komponente
  //},
  
  { 
    path: '/:pathMatch(.*)*', 
    redirect: '/',
    meta: { transition: 'bounce' },
  }
]
