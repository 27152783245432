import { createRouter, createWebHistory } from 'vue-router'
import { routes } from '@/router/routes'
import { nextTick } from 'vue'

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
  scrollBehavior: async (to, _from, savedPosition) => {
    // Wait for the DOM update cycle
    await nextTick() 

    // If a saved location exists, go back to it
    if (savedPosition) {
      return savedPosition
    }

    // If a hash fragment URL (e.g. #section-id) exists, scroll to it
    if (to.hash) {
      const element = document.querySelector(to.hash)
      if (element) {
        return {
          el: to.hash,
          behavior: 'smooth',
        }
      }
    }

    // Scroll up by default
    return { top: 0 } 
  },
})

export default router
