import '@/sass/main.scss'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createHead } from '@unhead/vue/client'

import App from './App.vue'
import Router from '@/router'

/// Create a Pinia instance with the data loaded
const Pinia = createPinia()

/// Create App instance
const app = createApp(App)

const Head = createHead()

/// Add functionality
app.use(Head)
app.use(Router)
app.use(Pinia)

Router.isReady().then(() => {
  app.mount('#app')
})
