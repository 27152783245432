<!--  

  ### IconCalcite Component
  This component renders SVG icons from the Calcite UI library with support for dynamic sizing, colors, and multiple paths.

  #### Props:
  - `iconName` (string): The name of the icon to render (required).
  - `size` (number | string, optional): The size of the icon. If not provided, it is extracted from the icon name or defaults to `16`.
  - `color` (string, optional): The fill color of the icon. Defaults to `currentColor`.

  ### Example Usage

  <icon-calcite 
    iconName="mobile16" 
    :size="32" 
    color="#007ac2" 
  />

-->
<script lang="ts" setup>

import { computed } from 'vue'
import * as icons from '@esri/calcite-ui-icons'

///////////////////////////////////////////////////////////////
/// Types
///////////////////////////////////////////////////////////////
interface IIconCalciteProps {
  iconName: string  // The name of the icon (required)
  size?: number | string // The size of the icon (optional, default extracted from icon name or 16)
  color?: string // The color of the icon (optional)              
}

///////////////////////////////////////////////////////////////
/// Props
///////////////////////////////////////////////////////////////
const props = defineProps<IIconCalciteProps>()

///////////////////////////////////////////////////////////////
/// Utility Functions
///////////////////////////////////////////////////////////////

/**
 * Extracts the size from the given icon name.
 * E.g., "plus16" would return `16`.
 * @param iconName - The name of the icon
 * @returns A number representing the size or `null` if not found
 */
 const extractSizeFromIconName = (iconName: string): number | null => {
  const sizeMatch = iconName.match(/\d+$/) // Match numbers at the end of the string
  return sizeMatch ? parseInt(sizeMatch[0], 10) : null // Return parsed number or null
}

///////////////////////////////////////////////////////////////
/// Computed Properties
///////////////////////////////////////////////////////////////

/**
 * Determines the size of the icon.
 * Priority:
 * 1. Explicit `size` prop if provided
 * 2. Extracted size from the `iconName`
 * 3. Default size of `16`
 */
 const computedSize = computed(() => {
  if (props.size) {
    return props.size // Use provided size
  }
  const sizeFromName = extractSizeFromIconName(props.iconName)
  return sizeFromName ?? 16 // Default to 16 if no size is found
})

/**
 * Retrieves the path data for the given icon name.
 * Handles:
 * - Single path (string)
 * - Multiple paths (array of path objects)
 * Logs a warning if the icon is not found or has an invalid format.
 */
const iconPath = computed(() => {
  const iconEntry = (icons as Record<string, unknown>)[props.iconName]

  if (typeof iconEntry === 'string') {
    return iconEntry // Single path icon
  } else if (Array.isArray(iconEntry)) {
    return iconEntry // Array of paths for complex icons
  } else {
    console.warn(`Icon "${props.iconName}" not found or has an invalid format.`)
    return '' // Fallback for invalid icons
  }
})

</script>
<template>

  <svg 
    :width="computedSize" 
    :height="computedSize" 
    :viewBox="`0 0 ${computedSize} ${computedSize}`"
    :style="{ fill: props.color || 'currentColor' }"
  >
    <!-- Render single path -->
    <path v-if="typeof iconPath === 'string'" :d="iconPath"></path>
    <!-- Render multiple paths -->
    <template v-else-if="Array.isArray(iconPath)">
      <path
        v-for="(pathEntry, index) in iconPath"
        :key="index"
        :d="pathEntry.path"
        :opacity="pathEntry.opacity || 1"
      />
    </template>
  </svg>

</template>
<style lang="scss" scoped>

svg {
  display: inline-block;
  vertical-align: middle;
  fill: var(--color-icon, #d8d8d8); // Default fill color
}

</style>
