import { defineStore, acceptHMRUpdate } from 'pinia'
import { type Component, reactive, markRaw } from 'vue'

///////////////////////////////////////////////////////////////
///////                                               /////////
///////   Types                                       /////////
///////                                               /////////
///////////////////////////////////////////////////////////////
interface IAppState {
  modal: IAppStateModal
}

interface IAppStateModal {
  isOpen: boolean
  content: Component | null
  props: Record<string, unknown> | null
}

///////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////
///////                                               /////////
///////   STORE                                       /////////
///////                                               /////////
///////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////
export const useAppStore = defineStore('app', () => {
  ///////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////
  ///////                                               /////////
  ///////   States                                      /////////
  ///////                                               /////////
  ///////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////
  const state = reactive<IAppState>({
    modal: {
      isOpen: false,
      content: null,
      props: null
    }
  })
  
  ///////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////
  ///////                                               /////////
  ///////   Actions                                     /////////
  ///////                                               /////////
  ///////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////
  function openModal(component: Component, props: Record<string, unknown>) {
    state.modal.content = markRaw(component)
    state.modal.props = props
    state.modal.isOpen = true
    console.log(state);
  }

  function closeModal() {
    state.modal.isOpen = false
    state.modal.content = null
    state.modal.props = null
  }

  return { 
    /// states
    state, 
    /// actions
    openModal,
    closeModal
  }
})

/// HMR
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot))
}
