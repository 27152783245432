<template>

  <div class="app">    
    <slot />
  </div>

</template>
<style lang="scss" >
@use "@/sass/mixins";

.app {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  
  position: relative;
}

</style>
